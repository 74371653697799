import { Component, OnInit, TemplateRef, Renderer2, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from './../environments/environment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';

import * as $ from "jquery";

enum content {
  section2,
  section3
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  @ViewChild('autoShownModal', { static: false }) autoShownModal: ModalDirective;
  isModalShown = false;

  private classCssInUse:string = '';
  public btnColor:string;

  arrayContent = [
    {
      file: '2019_folder_com_temas_Patricia_Tucci.pdf',
      title: 'Conecte-se bem :)',
      text: 'Sabe qual é o segredo para fortalecer suas habilidades humanas? Treinar constantemente. Portanto, imprima o calendário ou use-o como imagem no seu celular. E pratique cada a dia!',
      href: 'http://www.32.mktid9.com/w/1e1e2CU8e1S1Ula7iyKBe3a5a1f2-e!uid?egu=uh00cj32u2nbygxs',
      btnClass: 'btn-strong-red',
      filds : {
        nome: 'fname_1',
        ddd: 'ind_telefone_3',
        whatsapp: 'telefone_3_numero',
        email: 'email_2'
      }
    },
    {
      file: 'infografico_dicas_Nat_e_Pat.pdf',
      title: 'DESTAQUE-SE NO DIGITAL!',
      text: 'Como tudo na vida, o segredo para melhorar é começar! Para te estimular e te destacar na comunicação digital, Natália Leite e Patricia Tucci, se uniram para criar este guia prático e repleto de boas dicas. Faça o download abaixo e coloque e pratique já. Desperte a força digital que há em você ;)',
      href: 'http://www.32.mktid9.com/w/2e2e2CU8ewmznYRBsc0e7a602578e!uid?egu=4ofly7kqo6w3y9qt',
      btnClass: 'btn-strong-orange',
      filds : {
        nome: 'fname_4',
        ddd: 'ind_telefone_14',
        whatsapp: 'telefone_14_numero',
        email: 'email_5'
      }
    }
  ];

  title = 'SITE';
  pathOfProject = environment.api;

  modalRef: BsModalRef;
  message: string;
  downloaderService: any;
  contents: any;

  name = 'Angular 5';
  

  // variaveis para o modal
  modalHref:string;
  modalTitle:string;
  modalText:string;
  modalFile:string;

  fildNome:string;
  fildDDD:string;
  fildWhatsapp:string;
  fildEmail:string;


  constructor( private modalService: BsModalService, private renderer: Renderer2, private router: Router ) {

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd ) {
        if (event.url === '/sucesso') {
            this.showAlertModal();
        }
      }
    });
  }
  
  showAlertModal(): void {
    this.isModalShown = true;
  }
 
  hideAlertModal(): void {
    this.autoShownModal.hide();
  }
 
  onHiddenAlertModal(): void {
    this.isModalShown = false;
  }


  openModal(template: TemplateRef<any>, section:string, btnColor: string) {
    console.log(template);
      this.modalHref = this.arrayContent[content[section]].href;
      this.modalTitle = this.arrayContent[content[section]].title;
      this.modalText = this.arrayContent[content[section]].text;
      this.modalFile = '/assets/documents/' + this.arrayContent[content[section]].file;
      this.btnColor = this.arrayContent[content[section]].btnClass;

      this.fildNome = this.arrayContent[content[section]].filds.nome;
      this.fildDDD = this.arrayContent[content[section]].filds.ddd;
      this.fildWhatsapp = this.arrayContent[content[section]].filds.whatsapp;
      this.fildEmail = this.arrayContent[content[section]].filds.email;
      

      if (this.classCssInUse !== '') {
          this.renderer.removeClass(document.body, this.classCssInUse);
      }

      this.classCssInUse = section;
      this.renderer.addClass(document.body, section);
      this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
  }
  
  confirm(): void {
      $("#btn-submit").trigger("click");
  }

}
